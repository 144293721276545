import { FunctionComponent, HTMLAttributes, ReactElement } from "react";
import { Container, Grid, Typography } from "@mui/material";
import Image from "next/image";

import { styled } from "@mui/system";
import { fonts } from "~/theme";

import process from "./process.png";

interface Properties extends HTMLAttributes<HTMLElement> {}

const StyledContainer = styled(Container)(({ theme }) => ({
    marginTop: "144px",
    [theme.breakpoints.down("sm")]: {
        marginTop: "64px",
    },
}));

const ContentGrid = styled(Grid)(({ theme }) => ({
    paddingLeft: "56px",
    [theme.breakpoints.down("sm")]: {
        paddingLeft: 0,
    },
}));

const Section1 = styled(Typography)(({ theme }) => ({
    ...fonts.bannerBigTextSM,
    fontSize: "48px",
    [theme.breakpoints.down("sm")]: {
        marginTop: "51px",
        fontSize: "32px",
        lineHeight: "34px",
    },
}));

const Section2 = styled(Typography)(({ theme }) => ({
    ...fonts.bannerBigTextSM,
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "32px",
    lineHeight: "39px",
    marginTop: "45px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "22px",
        lineHeight: "28px",
        marginTop: "8px",
    },
}));

const Section3 = styled(Typography)(({ theme }) => ({
    ...fonts.bannerSmallTextSM,
    marginTop: "39px",
    [theme.breakpoints.down("sm")]: {
        fontSize: "13px",
        lineHeight: "17px",
        marginTop: "16px",
    },
}));

export const Process: FunctionComponent<Properties> = ({
    children,
    className,
    ...rest
}: Properties): ReactElement => {
    return (
        <StyledContainer className={className} {...rest}>
            <Grid container>
                <Grid item sm={6}>
                    <Image
                        width={742}
                        height={900}
                        src={process}
                        alt="Paul Kneale"
                        data-testid="author-image"
                    />
                </Grid>
                <ContentGrid item sm={6}>
                    <Section1>The Process is key.</Section1>

                    <Section2>
                        One of the most intriguing items to place in a microwave
                        is a CD.
                    </Section2>

                    <Section3>
                        When microwaved, CDs create a little lightning storm
                        inside the microwave. After being zapped the disk
                        emerges with a unique and mesmeric fractal pattern
                        burned into it. For the NFT, Kneale uses a high
                        resolution scanner to make 4800 dpi scans of the burned
                        disks, translating the intricate detail of the CD, and
                        creating rainbow effects in the reflective surface.
                    </Section3>
                </ContentGrid>
            </Grid>
        </StyledContainer>
    );
};
